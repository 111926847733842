$(document).ready(function() {
	if ($('.js-ratingStars').length > 0) {
		$('.js-ratingStars').rating({
					hoverOnClear: false,
					theme: 'krajee-fa'
			});
	}

	// SYNC SLIDER
	if ($('.js-sliderSyncLarge').length > 0) {
		$('.js-sliderSyncLarge').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			asNavFor: '.js-sliderSyncThumbnail',
			infinite: false,
			arrows: false
		});
		$('.js-sliderSyncThumbnail').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			asNavFor: '.js-sliderSyncLarge',
			focusOnSelect: true,
			arrows: true,
			infinite: false,
			swipe: false,

			// responsive: [
			// 	{
			// 		breakpoint: 992,
			// 		settings: {
			// 			slidesToShow: 4,
			// 			slidesToScroll: 1
			// 		}
			// 	}
			// ]
		});
	}
	if ($('.js-slider5').length > 0) {
		$('.js-slider5').slick({
			slidesToShow: 5,
			slidesToScroll: 5,
			arrows: true,
			infinite: false,

			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});
	}

	// PRODUCT ASIDE
	$('.js-prodAsideTrigger').on('click', function() {
		$('body').toggleClass('nav-opened');
		$('.js-prodAside').toggleClass('active');
		$('.js-prodAsideWrap').animate({scrollTop: 0}, 350)
	});

	// PRODUCT ASIDE DROPDOWN
	$('.js-dropTrigger').on('mousedown', function(e) {
		var trigger = $(this),
			parent = trigger.closest('.js-parent'),
			drop = trigger.closest('.js-drop'),
			list = drop.find('>.js-dropList');

		// parent.find('.js-drop').removeClass('active');
		drop.toggleClass('active');
		list.stop().toggle(350);
	});

	// PRICE RANGE
	if ($('.js-price').length > 0) {
		var range = $(this).find('.js-priceRange'),
			rangeMin = $(this).find('.js-priceMin'),
			rangeMax = $(this).find('.js-priceMax'),
			valMin = range.data('min'),
			valMax = range.data('max'),
			valStep = valMax / 20;

		range.slider({
			range: true,
			min: valMin,
			max: valMax,
			step: valStep,
			values: [ valMin, valMax ],
			slide: function( event, ui ) {
				rangeMin.empty().text(addCommas(ui.values[0]+' ₫'));
				rangeMax.empty().text(addCommas(ui.values[1]+' ₫'));
			}
		});
		rangeMin.empty().text(addCommas(range.slider("values", 0)+' ₫'));
		rangeMax.empty().text(addCommas(range.slider("values", 1)+' ₫'));
	}

	// TAB NAVIGATION
	$('.js-tabLink').on('click', function() {
		var target = $(this).data('target'),
			parent = $(this).closest('.js-tabParent');

		parent.find('.js-tabNav .js-tabLink').removeClass('active');
		parent.find('.js-tabContent .tab-panel').removeClass('active');
		$(target).addClass('active');
		$(this).addClass('active');
	})
})
.mousedown(function(e){
	var blur_off = $('.js-blurOff');
	if(!blur_off.is(e.target) && blur_off.has(e.target).length === 0){
		blur_off.removeClass('open');
		$('.navigation').removeClass('active');
		$('body').removeClass('nav-opened');
		$('.js-prodAside').removeClass('active');
		$('.js-dbAside').removeClass('opened');
	}
});